import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(theme => ({
    FormField: {
        fontSize: 16,
        '& *': {
            fontSize: 'inherit'
        },
        '& .MuiFormHelperText-root': {
            fontSize: 14,
        }
    },
    FormButton: {
        fontSize: 16,
        borderRadius: 2,
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
        transition: theme.transitions.duration.shorter + 'ms',
        minWidth: 160,
        '&:hover': {
            color: theme.palette.background.default,
        }
    },
    confirmationAnimation: ({subscribed}) => ({
        transition: '200ms',
        opacity: subscribed ? 1 : 0,
        transform: subscribed ? 'translateY(0)' : 'translateY(1rem)',
    })
}));